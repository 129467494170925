import amk from '../img/aboutme.png';

const MkAbout = (props) => {

    return(
        <div>
            <h1>{props.langData.get('amk')}</h1>
            <div className="about">
                <img src={amk} alt='About me' width='90%' />
                <div>
                    <p>{props.langData.get('amkp1')}</p>
                    <p>{props.langData.get('amkp2')}</p>
                </div>
            </div>
        </div>
    );

}

export default MkAbout;